import React, { useState, useContext, useCallback } from 'react';
import { IoIosSearch } from "react-icons/io";
import { fetchDataFromApi } from '../../../utils/api';
import { MyContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from 'lodash.debounce';
import Button from '@mui/material/Button';

const SearchBox = (props) => {
  const [searchFields, setSearchFields] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(MyContext);
  const navigate = useNavigate();

  const onChangeValue = useCallback(debounce((value) => {
    setSearchFields(value);
  }, 300), []);

  const handleChange = (e) => {
    onChangeValue(e.target.value);
  }

  const searchProducts = () => {
    if (searchFields !== "") {
      setIsLoading(true);
      fetchDataFromApi(`/api/search?q=${searchFields}`).then((res) => {
        context.setSearchData(res);
        setIsLoading(false);
        props.closeSearch();
        navigate("/search");
      }).catch(() => {
        setIsLoading(false);
      });
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      searchProducts();
    }
  }

  return (
    <div className='headerSearch ml-3 mr-3'>
      <input
        type='text'
        placeholder='Search for products...'
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        aria-label="Search for products"
      />
      <Button onClick={searchProducts} aria-label="Search">
        {isLoading ? <CircularProgress size={24} /> : <IoIosSearch />}
      </Button>
    </div>
  );
}

export default SearchBox;
