import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";
import tiktokIcon from "../../assets/images/tiktokIcon.png";
import visaIcon from "../../assets/images/Visa1.png";
import mastercardIcon from "../../assets/images/mastercardIcon.png";
import MtnIcon from "../../assets/images/MtnIcon.png";
import AirtelMoney from "../../assets/images/Airtel.svg";

// About Us Component
const AboutUs = () => (
  <div className="footer-section about-us">
    <h5>About Us</h5>
    <p>
    Welcome to Gift Gallery Store, your one-stop shop for luxury gifts,
     fashion items, and personalized services.
      We pride ourselves on offering high-quality products, 
      gift wrapping, and flower arrangements to make every occasion special. 
      Based in Kigali, Rwanda, we are dedicated to exceptional customer service and ensuring your shopping experience is seamless.


    </p>
    <p>
      Based in Kigali, Rwanda, we are dedicated to exceptional customer
      service.
    </p>
  </div>
);

// Contact Us Component
const ContactUs = () => (
  <div className="footer-section contact-us">
    <h5>Get in Touch</h5>
    <ul className="list-unstyled">
      <li>
        <FaPhoneAlt className="icon" aria-label="Phone" /> +250 (788 315-896)
      </li>
      <li>
        <FaEnvelope className="icon" aria-label="Email" />{" "}
        info@giftsgallerystore.com
      </li>
      <li>
        <FaMapMarkerAlt className="icon" aria-label="Kisimenti Address" /> Gift
        Gallery Store, Kisimenti branch <br /> KG 11 Ave, Kigali (Ikaze house,
        F1-D:22)
      </li>
      <li>
        <FaMapMarkerAlt className="icon" aria-label="Town Address" /> Gift
        Gallery Store, Town branch
        <br /> KN 70 St (TCB, behind Centenary house, F1B-009A)
      </li>
    </ul>
  </div>
);

// Payment Methods Component
const PaymentMethods = () => (
  <div className="footer-section payment-methods">
    <h5>Payment Methods</h5>
    <p>We accept:</p>
    <div className="payment-icons">
      <a
        href="https://visa.com"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Visa Payment Method"
      >
        <img src={visaIcon} alt="Visa" />
      </a>
      <a
        href="https://mastercard.com"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="MasterCard Payment Method"
      >
        <img src={mastercardIcon} alt="MasterCard" />
      </a>
      <a
        href="https://mtn.co.rw"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="MTN Mobile Money Payment Method"
      >
        <img src={MtnIcon} alt="MTN Money" />
      </a>
      <a
        href="https://airtelmoney.com"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Airtel Money Payment Method"
      >
        <img src={AirtelMoney} alt="Airtel Money" />
      </a>
    </div>
  </div>
);

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-grid">
          <AboutUs />
          <ContactUs />
          <PaymentMethods />
        </div>

        {/* Footer Bottom Section */}
        <div className="footer-bottom">
          <p>© {new Date().getFullYear()} Gift Gallery Store. All rights reserved.</p>
          <div className="social-icons">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
            <a
              href="https://tiktok.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="TikTok"
            >
              <img src={tiktokIcon} alt="TikTok" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
