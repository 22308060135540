import axios from "axios";

// Retrieve token from localStorage
const getToken = () => localStorage.getItem("token");

// Base URL from environment variable
const apiUrl = process.env.REACT_APP_API_URL;
if (!apiUrl) {
    throw new Error("API URL is not defined. Please check your environment variables.");
}

// A generic function for making API calls
const makeApiCall = async (method, url, data = null, additionalHeaders = {}) => {
    try {
        const config = {
            method,
            url: `${apiUrl}${url}`,
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
                ...additionalHeaders, // Merges additional headers if provided
            },
        };

        // Add data to config if it's provided
        if (data) {
            config.data = data;
            // If data is FormData, set content type to multipart/form-data
            if (data instanceof FormData) {
                config.headers['Content-Type'] = 'multipart/form-data';
            }
        }

        const response = await axios(config);
        return response.data; // Return response data on success
    } catch (error) {
        // Enhanced error handling
        const errorMsg = error.response?.data?.msg || "An error occurred.";
        const statusCode = error.response?.status;

        console.error(`API error (${statusCode}): ${errorMsg}`);

        if (statusCode === 401) {
            // Placeholder for token refresh logic if needed
            console.warn("Unauthorized: Token may have expired.");
        }

        return { status: false, msg: errorMsg };
    }
};

// Function for making GET requests to fetch data from API
export const fetchDataFromApi = (url) => makeApiCall("GET", url);

// Function for making POST requests to add data to API
export const postData = (url, formData) => makeApiCall("POST", url, formData);

// Function for making PUT requests to update data in the API
export const editData = (url, updatedData) => makeApiCall("PUT", url, updatedData);

// Function for making DELETE requests to delete data from API
export const deleteData = (url, data) => makeApiCall("DELETE", url, data);

// Function for uploading images to the API
export const uploadImage = (url, formData) => makeApiCall("POST", url, formData, {
    'Content-Type': 'multipart/form-data',
});

// Function for deleting images from the API
export const deleteImages = (url, image) => makeApiCall("DELETE", url, image);

// Function for handling Forgot Password requests
export const forgotPassword = (email) => {
    if (!email) {
        return { status: false, msg: "Email is required for password reset." };
    }
    return makeApiCall("POST", "/api/user/forgot-password", { email });
};
