import React, { useEffect, useRef, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo1.png";
import Button from "@mui/material/Button";
import CountryDropdown from "../CountryDropdown";
import { FiUser } from "react-icons/fi";
import { IoBagOutline } from "react-icons/io5";
import SearchBox from "./SearchBox";
import Navigation from "./Navigation";
import { MyContext } from "../../App";
import { FaEnvelope, FaClipboardCheck, FaHeart, FaUserAlt } from "react-icons/fa";
import { RiLogoutCircleRFill } from "react-icons/ri";
import { IoMdMenu, IoIosSearch } from "react-icons/io";
import { FaAngleLeft } from "react-icons/fa6";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const open = Boolean(anchorEl);

  const headerRef = useRef();
  const context = useContext(MyContext);
  const history = useNavigate();

  const handleMouseEnter = (event) => {
    if (context.windowWidth > 992) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMouseLeave = () => {
    if (context.windowWidth > 992) {
      setAnchorEl(null);
    }
  };

  const toggleDropdown = (event) => {
    if (context.windowWidth <= 992) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const logout = () => {
    setAnchorEl(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    context.setIsLogin(false);
    history("/signIn");
  };

  useEffect(() => {
    const handleScroll = () => {
      let position = window.pageYOffset;
      if (headerRef.current) {
        if (position > 100) {
          headerRef.current.classList.add("fixed");
        } else {
          headerRef.current.classList.remove("fixed");
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleNav = () => {
    setIsOpenNav((prev) => !prev);
    context.setIsOpenNav(!isOpenNav);
  };

  const openSearch = () => {
    setIsOpenSearch(true);
  };

  const closeSearch = () => {
    setIsOpenSearch(false);
  };

  return (
    <>
      <div className="headerWrapperFixed" ref={headerRef}>
        <div className="headerWrapper">
          <div className="top-strip bg-blue">
            <div className="container">
              <p className="mb-0 mt-0 text-center">
                <b></b>
              </p>
            </div>
          </div>

          <header className="header">
            <div className="container">
              <div className="row">
                <div className="logoWrapper d-flex align-items-center col-sm-2">
                  <Link to={"/"}>
                    <img src={Logo} alt="Logo" />
                  </Link>
                </div>

                <div className="col-sm-10 d-flex align-items-center part2">
                  {context.countryList.length !== 0 &&
                    context.windowWidth > 992 && <CountryDropdown />}

                  <div
                    className={`headerSearchWrapper ${
                      isOpenSearch && "open"
                    }`}
                  >
                    <div className="d-flex align-items-center">
                      <span
                        className="closeSearch mr-3"
                        onClick={closeSearch}
                      >
                        <FaAngleLeft />
                      </span>
                      <SearchBox closeSearch={closeSearch} />
                    </div>
                  </div>

                  <div className="part3 d-flex align-items-center ml-auto">
                    {context.windowWidth < 992 && (
                      <Button
                        className="circle ml-3"
                        onClick={openSearch}
                      >
                        <IoIosSearch />
                      </Button>
                    )}

                    {context.isLogin !== true && context.windowWidth > 992 && (
                      <Link to="/signIn">
                        <Button className="btn-blue btn-round mr-3">
                          Sign In
                        </Button>
                      </Link>
                    )}

                    {context.isLogin === true && (
                      <div
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={toggleDropdown}
                      >
                        <Button className="circle mr-3">
                          <FiUser />
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          id="accDrop"
                          open={open}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                          onClose={() => setAnchorEl(null)}
                        >
                          <Link to="/my-account">
                            <MenuItem onClick={handleMouseLeave}>
                              <ListItemIcon>
                                <FaUserAlt fontSize="small" />
                              </ListItemIcon>
                              My Account
                            </MenuItem>
                          </Link>
                          <Link to="/orders">
                            <MenuItem onClick={handleMouseLeave}>
                              <ListItemIcon>
                                <FaClipboardCheck fontSize="small" />
                              </ListItemIcon>
                              Orders
                            </MenuItem>
                          </Link>
                          <Link to="/my-list">
                            <MenuItem onClick={handleMouseLeave}>
                              <ListItemIcon>
                                <FaHeart fontSize="small" />
                              </ListItemIcon>
                              My List
                            </MenuItem>
                          </Link>
                          <Link to="/contactus">
                            <MenuItem onClick={handleMouseLeave}>
                              <ListItemIcon>
                                <FaEnvelope fontSize="small" />
                              </ListItemIcon>
                              Contact Us
                            </MenuItem>
                          </Link>
                          <MenuItem onClick={logout}>
                            <ListItemIcon>
                              <RiLogoutCircleRFill fontSize="small" />
                            </ListItemIcon>
                            Logout
                          </MenuItem>
                        </Menu>
                      </div>
                    )}

                    <div className="ml-auto cartTab d-flex align-items-center">
                      {context.windowWidth > 1000 && (
                        <span className="price">
                          {(context.cartData?.length !== 0
                            ? context.cartData
                                ?.map(
                                  (item) =>
                                    parseInt(item.price) * item.quantity
                                )
                                .reduce((total, value) => total + value, 0)
                            : 0
                          )?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "RWF",
                          })}
                        </span>
                      )}

                      <div className="position-relative ml-2">
                        <Link to="/cart">
                          <Button className="circle">
                            <IoBagOutline />
                          </Button>
                          <span className="count d-flex align-items-center justify-content-center">
                            {context.cartData?.length > 0
                              ? context.cartData?.length
                              : 0}
                          </span>
                        </Link>
                      </div>

                      {context.windowWidth < 992 && (
                        <Button
                          className="circle ml-3"
                          onClick={toggleNav}
                        >
                          <IoMdMenu />
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          {context.categoryData?.length !== 0 && (
            <Navigation
              navData={context.categoryData}
              isOpenNav={isOpenNav}
              closeNav={() => setIsOpenNav(false)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
