import Button from "@mui/material/Button";
import { IoIosMenu } from "react-icons/io";
import { FaAngleDown, FaAngleRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { MyContext } from "../../../App";
import CountryDropdown from "../../CountryDropdown";
import { fetchDataFromApi } from "../../../utils/api";

const Navigation = (props) => {
  const [isOpenSidebarVal, setIsOpenSidebarVal] = useState(false);
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [isOpenSubMenuIndex, setIsOpenSubMenuIndex] = useState(null);
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);
  const context = useContext(MyContext);

  useEffect(() => {
    setIsOpenNav(props.isOpenNav);
  }, [props.isOpenNav]);

  // Sort categories alphabetically or any other desired order
  const sortedNavData = props.navData?.sort((a, b) => a.name.localeCompare(b.name));

  const handleSubMenuToggle = (index) => {
    setIsOpenSubMenuIndex(index);
    setIsOpenSubMenu(!isOpenSubMenu);
  };

  return (
    <nav>
      <div className="container">
        <div className="row">
          <div className="col-sm-2 navPart1">
            <div className="catWrapper">
              <Button
                className="allCatTab align-items-center res-hide"
                onClick={() => setIsOpenSidebarVal(!isOpenSidebarVal)}
              >
                <span className="icon1 mr-2">
                  <IoIosMenu />
                </span>
                <span className="text">ALL CATEGORIES</span>
                <span className="icon2 ml-2">
                  <FaAngleDown />
                </span>
              </Button>

              <div className={`sidebarNav ${isOpenSidebarVal ? "open" : ""}`}>
                <ul>
                  {sortedNavData?.map((item, index) => (
                    <li key={item.id}>
                      <Link to={`/products/category/${item.id}`}>
                        <Button>
                          {item.name} <FaAngleRight className="ml-auto" />
                        </Button>
                      </Link>
                      {item.children && (
                        <div className="submenu">
                          {item.children.map((subCat, key) => (
                            <Link to={`/products/subCat/${subCat.id}`} key={key}>
                              <Button>{subCat.subcat}</Button>
                            </Link>
                          ))}
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div
            className={`col-sm-10 navPart2 d-flex align-items-center res-nav-wrapper ${
              isOpenNav ? "open" : "close"
            }`}
          >
            <div className="res-nav-overlay" onClick={props.closeNav}></div>
            <ul className="list list-inline ml-auto res-nav">
              {context.windowWidth < 992 && (
                <>
                  {context.isLogin !== true && (
                    <li className="list-inline-item pl-3">
                      <Link to="/signIn">
                        <Button className="btn-blue btn-round mr-3">Sign In</Button>
                      </Link>
                    </li>
                  )}
                  <li className="list-inline-item">
                    <div className="p-3">
                      {context.countryList.length !== 0 && context.windowWidth < 992 && (
                        <CountryDropdown />
                      )}
                    </div>
                  </li>
                </>
              )}
              <li className="list-inline-item" onClick={props.closeNav}>
                <Link to="/">
                  <Button>Home</Button>
                </Link>
              </li>
              {sortedNavData
                .slice(0, 8)
                .map((item, index) => (
                  <li className="list-inline-item" key={item.id}>
                    <Link to={`/products/category/${item.id}`} onClick={props.closeNav}>
                      <Button>{item.name}</Button>
                    </Link>
                    {item.children && context.windowWidth < 992 && (
                      <span
                        className={`arrow ${
                          isOpenSubMenuIndex === index && isOpenSubMenu ? "rotate" : ""
                        }`}
                        onClick={() => handleSubMenuToggle(index)}
                      >
                        <FaAngleDown />
                      </span>
                    )}
                    {item.children && (
                      <div
                        className={`submenu ${
                          isOpenSubMenuIndex === index && isOpenSubMenu ? "open" : ""
                        }`}
                      >
                        {item.children.map((subCat, key) => (
                          <Link
                            to={`/products/subCat/${subCat.id}`}
                            key={key}
                            onClick={props.closeNav}
                          >
                            <Button>{subCat.subcat}</Button>
                          </Link>
                        ))}
                      </div>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
