import React, { createContext, useState, useEffect, lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./responsive.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { fetchDataFromApi, postData } from "./utils/api";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import ProductModal from "./Components/ProductModal";

// Lazy loading pages for performance
const Home = lazy(() => import("./Pages/Home"));
const Listing = lazy(() => import("./Pages/Listing"));
const ProductDetails = lazy(() => import("./Pages/ProductDetails"));
const Cart = lazy(() => import("./Pages/Cart"));
const SignIn = lazy(() => import("./Pages/SignIn"));
const SignUp = lazy(() => import("./Pages/SignUp"));
const MyList = lazy(() => import("./Pages/MyList"));
const Checkout = lazy(() => import("./Pages/Checkout"));
const Orders = lazy(() => import("./Pages/Orders"));
const MyAccount = lazy(() => import("./Pages/MyAccount"));
const SearchPage = lazy(() => import("./Pages/Search"));
const PaymentMethods = lazy(() => import("./Pages/PaymentMethods/PaymentMethods"));
const ForgotPasswordPage = lazy(() => import("./Pages/ForgotPassword/ForgotPasswordPage"));
const ResetPasswordPage = lazy(() => import("./Pages/ResetPassword/ResetPasswordPage"));
const ContactUs = lazy(() => import("./Pages/ContactUs/ContactUs"));

const MyContext = createContext();

function App() {
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setselectedCountry] = useState("");
  const [isOpenProductModal, setisOpenProductModal] = useState(false);
  const [isHeaderFooterShow, setisHeaderFooterShow] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const [productData, setProductData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setsubCategoryData] = useState([]);
  const [addingInCart, setAddingInCart] = useState(false);
  const [cartData, setCartData] = useState();
  const [searchData, setSearchData] = useState([]);
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [alertBox, setAlertBox] = useState({
    msg: "",
    error: false,
    open: false,
  });
  const [user, setUser] = useState({
    name: "",
    email: "",
    userId: "",
  });

  const location = useLocation();

  // Check current route to toggle header/footer visibility
  const noHeaderFooterRoutes = ["/forgotpassword", "/resetpassword/:token", "/signIn", "/signUp"];
  useEffect(() => {
    setisHeaderFooterShow(!noHeaderFooterRoutes.includes(location.pathname));
  }, [location]);

  // Fetch initial data and manage states
  useEffect(() => {
    async function initializeData() {
      try {
        const countries = await axios.get("https://countriesnow.space/api/v0.1/countries/");
        setCountryList(countries.data.data);
        const categoryRes = await fetchDataFromApi("/api/category");
        setCategoryData(categoryRes.categoryList);
        const subCategories = categoryRes.categoryList.flatMap((cat) => cat?.children || []);
        setsubCategoryData(subCategories);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    }

    initializeData();

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // const location = localStorage.getItem("location");
    const location = "All";
    setselectedCountry(location || "All");

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser?.userId) {
      fetchDataFromApi(`/api/cart?userId=${storedUser.userId}`)
        .then(setCartData)
        .catch((err) => console.error("Error fetching cart data:", err));
    }
  }, [isLogin]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLogin(true);
      setUser(JSON.parse(localStorage.getItem("user")));
    } else {
      setIsLogin(false);
    }
  }, [isLogin]);

  const openProductDetailsModal = async (id, status) => {
    try {
      const product = await fetchDataFromApi(`/api/products/${id}`);
      setProductData(product);
      setisOpenProductModal(status);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  const addToCart = async (data) => {
    if (isLogin) {
      setAddingInCart(true);
      try {
        const res = await postData(`/api/cart/add`, data);
        if (!res.status) {
          throw new Error(res.msg);
        }
        setAlertBox({ open: true, error: false, msg: "Item is added in the cart" });
        await getCartData();
      } catch (error) {
        setAlertBox({ open: true, error: true, msg: error.message });
      } finally {
        setAddingInCart(false);
      }
    } else {
      setAlertBox({ open: true, error: true, msg: "Please login first" });
    }
  };

  const getCartData = async () => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser?.userId) {
      try {
        const cart = await fetchDataFromApi(`/api/cart?userId=${storedUser.userId}`);
        setCartData(cart);
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== "clickaway") {
      setAlertBox({ open: false });
    }
  };

  const contextValues = {
    countryList,
    setselectedCountry,
    selectedCountry,
    isOpenProductModal,
    setisOpenProductModal,
    isHeaderFooterShow,
    setisHeaderFooterShow,
    isLogin,
    setIsLogin,
    categoryData,
    setCategoryData,
    subCategoryData,
    setsubCategoryData,
    openProductDetailsModal,
    alertBox,
    setAlertBox,
    addToCart,
    addingInCart,
    setAddingInCart,
    cartData,
    setCartData,
    getCartData,
    searchData,
    setSearchData,
    windowWidth,
    isOpenNav,
    setIsOpenNav,
  };

  return (
    <div>
      <MyContext.Provider value={contextValues}>
        <Snackbar
          open={alertBox.open}
          autoHideDuration={6000}
          onClose={handleClose}
          className="snackbar"
        >
          <Alert
            onClose={handleClose}
            severity={alertBox.error ? "error" : "success"}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {alertBox.msg}
          </Alert>
        </Snackbar>
        {isHeaderFooterShow && <Header />}
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/products/category/:id" exact element={<Listing />} />
            <Route path="/products/subCat/:id" exact element={<Listing />} />
            <Route path="/product/:id" exact element={<ProductDetails />} />
            <Route path="/cart" exact element={<Cart />} />
            <Route path="/signIn" exact element={<SignIn />} />
            <Route path="/signUp" exact element={<SignUp />} />
            <Route path="/my-list" exact element={<MyList />} />
            <Route path="/checkout" exact element={<Checkout />} />
            <Route path="/orders" exact element={<Orders />} />
            <Route path="/my-account" exact element={<MyAccount />} />
            <Route path="/search" exact element={<SearchPage />} />
            <Route path="/paymentmethods" exact element={<PaymentMethods />} />
            <Route path="/forgotpassword" exact element={<ForgotPasswordPage />} />
            <Route path="/resetpassword/:token" exact element={<ResetPasswordPage />} />
            <Route path="/contactus" exact element={<ContactUs />} />
          </Routes>
        </Suspense>
        {isHeaderFooterShow && <Footer />}
        {isOpenProductModal && <ProductModal data={productData} />}
      </MyContext.Provider>
    </div>
  );
}

export default App;
export { MyContext };
